import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { MDXRenderer } from "gatsby-plugin-mdx";
import LinksBox from "../components/LinksBox";
import { BasicHeadTags } from "../components/SEO/BasicHeadTags";
import { content_wrapper } from "../styles/modules/content.module.css";

const PagePaletteColor = ({ data }) => {
  const { mdx } = data;

  return (
    <Layout locale={mdx.frontmatter.locale}>
      <article className={content_wrapper}>
        <h1>{mdx.frontmatter.title} </h1>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </article>
      <LinksBox locale={mdx.frontmatter.locale} slug={mdx.frontmatter.slug} />
    </Layout>
  );
};

export const Head = ({ data }) => {
  const { mdx } = data;

  return (
    <BasicHeadTags
      title={mdx.frontmatter.title}
      description={mdx.frontmatter.metaDescription}
      slug={mdx.frontmatter.slug}
      noindex={true}
    />
  );
};

export default PagePaletteColor;

export const data = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      body
      id
      frontmatter {
        title
        metaTitle
        metaDescription
        slug
        locale
      }
    }
  }
`;
